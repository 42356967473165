import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../components/RawHTML';
import Container from '../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTDocumentConversion CreateInternal:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentConversion GetHandleInternal]</div>
<div class="difference"><span class="status added">Added</span> PTExternalAnnotManager</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager MergeXFDF:]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager Undo]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager GetLastXFDF]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager Redo]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager GetNextRedoInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager GetNextUndoInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager TakeSnapshot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTExternalAnnotManager init]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView GetExternalAnnotManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.externalAnnotManager</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl EnableAnnotationManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotManagerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotManagerDelegate localChangeWithAction:command:json:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotManager</div>
<div class="difference"><span class="status added">Added</span> PTAnnotManager.delegate</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotManager initWithExternalManager:toolManager:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotManager GetLastXFDF]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotManager MergeXFDF:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotManager takeSnapshot:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:shouldExportCachedDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentViewControllerDelegate documentViewController:shouldDeleteCachedDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.httpRequestOptions</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeHandCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFreeHandCreate.pencilMode</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTOutlineViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTOutlineViewController initWithToolManager:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPageIndicatorViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPageIndicatorViewController presentGoToPageController]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTRotateWidgetView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTRotateWidgetView</div>
<div class="difference"><span class="status added">Added</span> PTRotateWidgetView.diameter</div>
<div class="difference"><span class="status added">Added</span> -[PTRotateWidgetView initAtPoint:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTRotateHandleLocation</div>
<div class="difference"><span class="status added">Added</span> PTRotateHandleLocationTop</div>
<div class="difference"><span class="status added">Added</span> PTRotateHandleLocationLeft</div>
<div class="difference"><span class="status added">Added</span> PTRotateHandleLocationBottom</div>
<div class="difference"><span class="status added">Added</span> PTRotateHandleLocationRight</div>
<div class="difference"><span class="status added">Added</span> PTSelectionRectContainerView.rotationHandle</div>
<div class="difference"><span class="status added">Added</span> -[PTSelectionRectContainerView setRotationHandleLocation:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.pencilTool</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.digitalCertificateLocation</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager enableAnnotManager:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.annotManager</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}